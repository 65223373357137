import _ from 'lodash';
import React from 'react';
import TransactionConstants from 'spa/constants/TransactionConstants';

function checkActionRequired(transaction, isCancelled) {
  if (transaction.role === TransactionConstants.TRANSACTION_ROLES.SELLER) {
    if (transaction.isOffer) {
      // Offers are actionable by sellers
      return true;
    }
    if (transaction.isAwaitingDisbursementInfo && !isCancelled) {
      // Transactions needing seller disbursement info are actionable for sellers
      return true;
    }
  }

  const requiredAction = TransactionConstants.TRANSACTION_STATUSES.requireAction[transaction.role];
  if (!requiredAction) {
    return false;
  }

  if (requiredAction.transaction.includes(transaction.statusCode)) {
    return true;
  }

  // Look up if the milestone status is actionable
  if (transaction.type !== 'milestone') {
    return false;
  }

  return _.intersection(
    TransactionConstants.TRANSACTION_STATUSES.requireAction[transaction.role].milestone,
    transaction.milestoneStatuses
  ).length > 0;
}

const TransactionTags = ({ transaction }) => {
  const isAwaitingAgreement = TransactionConstants.TRANSACTION_STATUSES.awaitingAgreement.includes(
    transaction.statusCode
  );
  const isAwaitingPayment = TransactionConstants.TRANSACTION_STATUSES.awaitingPayment.includes(
    transaction.statusCode
  );
  const isAwaitingPaymentArrival =
    TransactionConstants.TRANSACTION_STATUSES.awaitingPaymentArrival.includes(
      transaction.statusCode
    );
  const isReviewingReceivedPayment =
    TransactionConstants.TRANSACTION_STATUSES.reviewingReceivedPayment.includes(
      transaction.statusCode
    );
  const isAwaitingDelivery = TransactionConstants.TRANSACTION_STATUSES.awaitingDelivery.includes(
    transaction.statusCode
  );
  const isDelivery = TransactionConstants.TRANSACTION_STATUSES.delivery.includes(
    transaction.statusCode
  );
  const isInspection = TransactionConstants.TRANSACTION_STATUSES.inspection.includes(
    transaction.statusCode
  );
  const isCompleted = TransactionConstants.TRANSACTION_STATUSES.completed.includes(
    transaction.statusCode
  );

  const isDnh = TransactionConstants.TRANSACTION_STATUSES.dnh.includes(transaction.statusCode);
  const isMilestone = TransactionConstants.TRANSACTION_STATUSES.milestone.includes(
    transaction.statusCode
  );
  const isEscrowOffer = transaction.isOffer;
  const isInDispute = transaction.isInDispute;
  const isCancelled =
    !transaction.isDraft &&
    TransactionConstants.TRANSACTION_STATUSES.cancelled.includes(transaction.statusCode);
  const isTransactionOnHold = TransactionConstants.TRANSACTION_STATUSES.transactionOnHold.includes(
    transaction.statusCode
  );
  const isPendingCancellation =
    TransactionConstants.TRANSACTION_STATUSES.pendingCancellation.includes(transaction.statusCode);
  const isAwaitingDisbursementInfo =
    transaction.role === TransactionConstants.TRANSACTION_ROLES.SELLER &&
    transaction.isAwaitingDisbursementInfo && !isCancelled;

  const isActionRequired = checkActionRequired(transaction, isCancelled);

  return (
    <div className="transactions-tags-container">
      {isAwaitingAgreement && (
        <span className="transactions-tags-item transactions-tags-item--awaiting">
          Awaiting Agreement
        </span>
      )}
      {isAwaitingPayment && (
        <span className="transactions-tags-item transactions-tags-item--ongoing">
          Awaiting Payment
        </span>
      )}
      {isAwaitingPaymentArrival && (
        <span className="transactions-tags-item transactions-tags-item--ongoing">
          Awaiting Payment Arrival
        </span>
      )}
      {isReviewingReceivedPayment && (
        <span className="transactions-tags-item transactions-tags-item--ongoing">
          Reviewing Received Payment
        </span>
      )}
      {isAwaitingDelivery && (
        <span className="transactions-tags-item transactions-tags-item--ongoing">
          Awaiting Delivery
        </span>
      )}
      {isDelivery && (
        <span className="transactions-tags-item transactions-tags-item--ongoing">Delivery</span>
      )}
      {isInspection && (
        <span className="transactions-tags-item transactions-tags-item--ongoing">Inspection</span>
      )}
      {isCompleted && (
        <span className="transactions-tags-item transactions-tags-item--completed">Completed</span>
      )}

      {isDnh && (
        <span className="transactions-tags-item transactions-tags-item--type">DNH Service</span>
      )}
      {isMilestone && (
        <span className="transactions-tags-item transactions-tags-item--type">
          Milestone Transaction Open
        </span>
      )}
      {isEscrowOffer && (
        <span className="transactions-tags-item transactions-tags-item--type">Escrow Offer</span>
      )}
      {isInDispute && (
        <span className="transactions-tags-item transactions-tags-item--action">In Dispute</span>
      )}
      {isCancelled && <span className="transactions-tags-item">Cancelled</span>}
      {isTransactionOnHold && <span className="transactions-tags-item">Transaction On Hold</span>}
      {isAwaitingDisbursementInfo && (
        <span className="transactions-tags-item">Needs Disbursement Info</span>
      )}
      {isPendingCancellation && (
        <span className="transactions-tags-item">Pending Cancellation</span>
      )}

      {isActionRequired && (
        <span className="transactions-tags-item transactions-tags-item--action">
          Requires Action
        </span>
      )}
    </div>
  );
};
export default TransactionTags;
