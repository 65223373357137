import { TransactionStatusToCode, MilestoneStatusToCode } from 'escrow-common-js/dist/constants';
import { gettext } from '../../utils/filters';

const constants = {
  V3_FORM_NAME: 'StartTransactionV3',
  TOUR_NAME: 'transactions',
  TOUR_STEP: {
    READY: -1,
    OUTSTANDING: 1,
    OPEN: 2,
    CLOSED: 3,
    FILTER: 4,
    SORT: 5,
    DONE: 6,
  },

  DESKTOP_VIEW_WIDTH: 680,
  TOUR_FLAG_ADDING: {
    SKIPPED_MESSAGE: 'Skip Adding Flag HasTouredMyTransactionsV2',
  },
  // Actions
  CHECK_TOUR_STATUS: 'TransactionConstants.CHECK_TOUR_STATUS',
  SET_TOURED: 'TransactionConstants.SET_TOURED',

  GET_TRANSACTIONS: 'TransactionConstants.GET_TRANSACTIONS',
  SET_FILTERS: 'TransactionConstants.SET_FILTERS',

  CHANGE_BUYER_EMAIL: 'TransactionConstants.CHANGE_BUYER_EMAIL',
  CHANGE_DRAFT_BUYER_EMAIL: 'TransactionConstants.CHANGE_DRAFT_BUYER_EMAIL',
  UPDATE_QUERY_PARAMETERS: 'TransactionConstants.UPDATE_QUERY_PARAMETERS',

  GET_TRANSACTION_BY_ID: 'GET_TRANSACTION_BY_ID',
  GET_TRANSACTION_EXTRA_DETAILS: 'GET_TRANSACTION_EXTRA_DETAILS',
  SET_TRANSACTION_DRAFT: 'SET_TRANSACTION_DRAFT',
  SELECT_PAYMENT_METHOD: 'SELECT_PAYMENT_METHOD',
  GET_PAYMENT_METHODS: 'GET_PAYMENT_METHODS',
  GET_ADYEN_METHODS: 'GET_ADYEN_METHODS',
  SUBMIT_CREDIT_CARD_PAYMENT: 'SUBMIT_CREDIT_CARD_PAYMENT',
  FETCH_CREDIT_CARD_PAGE_DATA: 'FETCH_CREDIT_CARD_PAGE_DATA',
  SUBMIT_POLI_PAYMENT: 'TransactionConstants.SUBMIT_POLI_PAYMENT',
  SUBMIT_PLAID_PAYMENT: 'TransactionConstants.SUBMIT_PLAID_PAYMENT',
  START_TRANSACTION: 'START_TRANSACTION',
  FETCH_TRANSACTION_FEES_SUMMARY: 'FETCH_TRANSACTION_FEES_SUMMARY',

  LOAD_MORE_INCREMENT: 10,

  TRANSACTION_TYPES: {
    DOMAIN_NAME: 'domain_name',
    DOMAIN_NAME_HOLDING: 'domain_name_holding',
    MOTOR_VEHICLE: 'motor_vehicle',
    GENERAL_MERCHANDISE: 'general_merchandise',
    MILESTONE: 'milestone',
  },

  TRANSACTION_ROLES: {
    BUYER: 'buyer',
    SELLER: 'seller',
    BROKER: 'broker',
  },

  BULK_TRANSACTION_ROLES: {
    BUYER: 'buyer',
    SELLER: 'seller',
    BROKER: 'broker',
    PARTNER: 'partner',
  },

  PAYMENT_FEE_TYPES: {
    credit_card: gettext('Payment Processing Fee'),
    paypal: gettext('Payment Processing Fee'),
    wire_transfer: gettext('Payment Processing Fee'),
  },

  PAYMENT_OPTIONS: {
    credit_card: gettext('Pay with Credit or Debit Card'),
    paypal: gettext('Pay with Paypal'),
    wire_transfer: gettext('Pay with Wire Transfer'),
  },

  EXCLUDE_ITEM_FEE_TYPES: ['credit_card', 'intermediary'],
  POLI_PAYMENT_FLOWS: {
    escrow_pay: 'escrow_pay',
    main_funnel: 'main_funnel',
  },

  TRANSACTION_PROGRESS_STATUS_CODES: {
    AGREEMENT: {
      step: 1,
      status_codes: [1, 3, 5, 6, 9, 10, 15],
    },
    PAYMENT: {
      step: 2,
      status_codes: [18, 19, 20, 21, 22],
    },
    SHIPPING: {
      step: 3,
      status_codes: [25, 26, 30],
    },
    INSPECTION: {
      step: 4,
      status_codes: [35, 40, 43, 45, 46, 50, 55, 60, 65],
    },
    CLOSED: {
      step: 5,
      status_codes: [66, 67, 68, 80, 85],
    },
  },

  BROKER_DISCLOSURE: [
    {
      value: 'confidential',
      label: 'Confidential',
    },
    {
      value: 'transparentToBuyerOnly',
      label: 'Transparent to Buyer Only',
    },
    {
      value: 'transparentToSellerOnly',
      label: 'Transparent to Seller Only',
    },
    {
      value: 'transparentToBuyerAndSeller',
      label: 'Transparent to Buyer and Seller',
    },
  ],

  TRANSACTION_STATUSES: {
    awaitingAgreement: [
      TransactionStatusToCode.BROKER_STARTS,
      TransactionStatusToCode.BUYER_AGREE,
      TransactionStatusToCode.BUYER_AGREE_BROKERED,
      TransactionStatusToCode.SELLER_AGREE_BROKERED,
      TransactionStatusToCode.SELLER_AGREE,
      TransactionStatusToCode.DNH_ALL_AGREED,
    ],
    awaitingPayment: [TransactionStatusToCode.ALL_PARTIES_AGREED],
    awaitingPaymentArrival: [TransactionStatusToCode.BUYER_MARKED_AS_PAID],
    reviewingReceivedPayment: [TransactionStatusToCode.PAYMENT_RECEIVED],
    awaitingDelivery: [
      TransactionStatusToCode.PAYMENT_SECURED,
      TransactionStatusToCode.BUYER_REJECTED,
    ],
    delivery: [TransactionStatusToCode.SELLER_SHIPPED, TransactionStatusToCode.BUYER_SHIPPED],
    inspection: [
      TransactionStatusToCode.BUYER_RECEIVED,
      TransactionStatusToCode.SELLER_RECEIVED,
      TransactionStatusToCode.BUYER_ACCEPTED,
      TransactionStatusToCode.BUYER_PARTIAL_REJECTED,
      TransactionStatusToCode.SELLER_ACCEPTED,
      TransactionStatusToCode.SELLER_REJECTED,
      TransactionStatusToCode.MILESTONES_ALL_ACCEPTED,
      TransactionStatusToCode.MILESTONES_SOME_ACCEPTED,
      TransactionStatusToCode.MILESTONES_ALL_REJECTED,
    ],
    completed: [TransactionStatusToCode.CLOSED_ACCEPTED, TransactionStatusToCode.CLOSED_REJECTED],
    dnh: [TransactionStatusToCode.DOMAIN_NAME_HOLDING],
    milestone: [TransactionStatusToCode.MILESTONES_OPEN],
    cancelled: [TransactionStatusToCode.CANCELLED],
    transactionOnHold: [TransactionStatusToCode.ON_HOLD],
    pendingCancellation: [TransactionStatusToCode.CANCELLATION_PENDING],
    requireAction: {
      buyer: {
        transaction: [
          TransactionStatusToCode.BROKER_STARTS,
          TransactionStatusToCode.SELLER_AGREE_BROKERED,
          TransactionStatusToCode.SELLER_AGREE,
          TransactionStatusToCode.ALL_PARTIES_AGREED,
          TransactionStatusToCode.SELLER_SHIPPED,
          TransactionStatusToCode.BUYER_RECEIVED,
          TransactionStatusToCode.BUYER_REJECTED,
        ],
        milestone: [
          MilestoneStatusToCode.SELLER_DELIVERED,
          MilestoneStatusToCode.SELLER_SHIPPED,
          MilestoneStatusToCode.BUYER_RECEIVED,
          MilestoneStatusToCode.BUYER_REJECTED,
        ],
      },
      seller: {
        transaction: [
          TransactionStatusToCode.BROKER_STARTS,
          TransactionStatusToCode.BUYER_AGREE,
          TransactionStatusToCode.BUYER_AGREE_BROKERED,
          TransactionStatusToCode.PAYMENT_SECURED,
          TransactionStatusToCode.BUYER_SHIPPED,
          TransactionStatusToCode.SELLER_RECEIVED,
        ],
        milestone: [
          MilestoneStatusToCode.PAYMENT_SECURED,
          MilestoneStatusToCode.BUYER_RETURNED,
          MilestoneStatusToCode.BUYER_SHIPPED,
          MilestoneStatusToCode.SELLER_RECEIVED,
        ],
      },
    },
  },

  BUYER_MESSAGE_PER_STATUS: [
    {
      code: 3,
      description:
        'The Broker has provided the terms of this transaction. The next step is to agree to the transaction terms. Modifying the terms will be subject to the approval of the Seller.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 5,
      description:
        'You have agreed to the transaction. The transaction will proceed once the Seller agrees to the terms.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 6,
      description:
        'You have agreed to the transaction. The transaction will proceed once the Seller agrees to the terms.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 9,
      description:
        'The Seller has agreed to the transaction. The transaction will proceed once you agree to the terms. Modifying the terms will be subject to the approval of the Seller.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 10,
      description:
        'The Seller has agreed to the transaction. The transaction will proceed once you agree to the terms. Modifying the terms will be subject to the approval of the Seller.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 15,
      description:
        'Great news! The transaction has been agreed to. The next step is to send the payment.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 18,
      description:
        'Almost there! The transaction has been agreed to. The next step is to send the payment.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 20,
      description:
        'Thank you for sending your payment. We will let you know once we have received it.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 22,
      description:
        'Thank you for sending your payment. We have received the funds and we will now secure it for the transaction.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 25,
      description:
        'Thank you for sending your payment. We have secured the funds and we have informed the Seller to ship the merchandise.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 26,
      description:
        'The Milestone Transaction is now open. Let us know the status of each milestone as they are completed.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 30,
      description:
        'Ready to check out what you bought? The seller has provided the tracking details for your shipment. Let us know if you have received the merchandise. The inspection period will start upon receipt.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 35,
      description:
        'The Inspection Period has now started! Let us now if you accept the merchandise to start the process for releasing the funds. If you are rejecting, let us know any problems you have with the merchandise.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 40,
      description:
        'You have now accepted the merchandise. We will now start the process to release the funds to the Seller.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 45,
      description:
        'You have rejected the merchandise. Please ship the merchandise to the address provided by the Seller. Submit the tracking details if you have shipped it.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 50,
      description:
        'You have now shipped the merchandise to the Seller. Upon their receipt, they may accept or reject the returned merchandise.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 55,
      description:
        'The Seller has now received the returned merchandise. The Inspection Period now starts for the Seller to decide whether to accept or reject the returned merchandise.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 60,
      description:
        'Great news! The Seller has accepted the returned the merchandise. We will now begin to process your refund. Thank you for using Escrow.com.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 65,
      description:
        'The Seller has rejected the returned merchandise. The transaction will now proceed into dispute. Please let us know of the resolution you have agreed on.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 80,
      description:
        'Congratulations on completing your transaction with Escrow.com! You may view the Invoice or the Summary of this transaction below:',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 85,
      description:
        'Your transaction with Escrow.com is now complete. You may view the Invoice or the Summary of this transaction below:',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
  ],

  SELLER_MESSAGE_PER_STATUS: [
    {
      code: 3,
      description:
        'The Broker has provided the terms of this transaction. The next step is to agree to the transaction terms. Modifying the terms will be subject to the approval of the Buyer.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 5,
      description:
        'The Buyer has provided the terms of this transaction. The next step is to agree to the transaction terms. Modifying the terms will be subject to the approval of the Buyer.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 6,
      description:
        'The Buyer has agreed to the transaction. The transaction will proceed once you agree to the terms. Modifying the terms will be subject to the approval of the Seller.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 9,
      description:
        'You have agreed to the transaction. The transaction will proceed once the Buyer agrees to the terms.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 10,
      description:
        'You have agreed to the transaction. The transaction will proceed once the Buyer agrees to the terms.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 15,
      description:
        'Great news! The transaction has been agreed to. We are now waiting for the Buyer to send the payment.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 18,
      description:
        'Great news! The transaction has been agreed to. We are now waiting for the Buyer to send the payment.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 20,
      description:
        'The Buyer has marked the payment as sent. We will let you know once we have received it.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 22,
      description:
        'The Buyer has sent the payment. We have received the funds and we will now secure it for the transaction.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 25,
      description:
        'Great news! We have secured the funds for the transaction. You may now ship the items to the given address of the buyer. Submit the tracking details if you have shipped the item.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 26,
      description:
        'The Milestone Transaction is now open. Let us know the status of each milestone as they are completed.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 30,
      description:
        'We are now waiting for the Buyer to confirm receipt. No need to do anything from your side for now.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 35,
      description:
        'The Inspection Period has now started! We are now waiting for the Buyer to accept the merchandise. No need to do anything from your side for now.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 40,
      description:
        'Great news! The Buyer has accepted the merchandise. We will now start the process to release the funds.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 45,
      description:
        'The Buyer rejected the merchandise. The merchandise should be shipped back to you soon for inspection.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 50,
      description:
        'The Buyer has provided the tracking details for your shipment. Let us know if you have received the merchandise. The inspection period will start upon receipt.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 55,
      description:
        'You have received the returned merchandise. The Inspection Period for the returned merchandise will now start.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 60,
      description: 'You have accepted the returned merchandised. Thank you for using Escrow.com.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 65,
      description:
        'You have rejected the returned merchandise. The transaction will now proceed into dispute. Please let us know of the resolution you have agreed on.',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 80,
      description:
        'Congratulations on completing your transaction with Escrow.com! You may view the Invoice or the Summary of this transaction below:',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
    {
      code: 85,
      description:
        'Your transaction with Escrow.com is now complete. You may view the Invoice or the Summary of this transaction below:',
      cta: 'View Required Action',
      url: (transactionId) => `${window.config.www_base_url}/transaction/${transactionId}`,
    },
  ],
  CSV_UPLOAD_ERROR: 'CSV_UPLOAD_ERROR',
};

constants.CATEGORY_OTHER_MERCHANDISE = 'other_merchandise';

constants.TRANSACTION_TYPES_CATEGORY = {
  [constants.TRANSACTION_TYPES.DOMAIN_NAME]: [
    {
      label: 'Domain',
      value: 'domain',
    },
    {
      label: 'Website',
      value: 'website',
    },
  ],
  [constants.TRANSACTION_TYPES.MOTOR_VEHICLE]: [
    {
      label: 'Cars & Trucks',
      value: 'automobiles_cars_and_trucks',
    },
    {
      label: 'Boats',
      value: 'boats',
    },
    {
      label: 'Motorcycles',
      value: 'motorcycles',
    },
    {
      label: 'Airplanes',
      value: 'airplanes',
    },
    {
      label: 'Other Motor Vehicles',
      value: 'other_motor_vehicles',
    },
  ],
  [constants.TRANSACTION_TYPES.GENERAL_MERCHANDISE]: [
    {
      label: 'Antiques & Collectibles',
      value: 'antiques_and_collectibles',
    },
    {
      label: 'Appliances',
      value: 'appliances',
    },
    // {
    //   label: 'Adult Material',
    //   value: 'adult_material',
    // },
    // {
    //   label: 'Alcohol & Wine',
    //   value: 'alcohol_and_wine',
    // },
    {
      label: 'Arts & Crafts',
      value: 'arts_and_crafts',
    },
    {
      label: 'Automotive or Industrial Parts',
      value: 'automotive_or_industrial_parts',
    },
    {
      label: 'Beauty & Health',
      value: 'beauty_and_health',
    },
    {
      label: 'Books & Magazines',
      value: 'books_and_magazines',
    },
    // {
    //   label: 'Cigarettes & Tobacco',
    //   value: 'cigarettes_and_tobacco',
    // },
    {
      label: 'Clothing & Accessories',
      value: 'clothing_and_accessories',
    },
    {
      label: 'Computer Hardware & Software',
      value: 'computer_hardware_and_software',
    },
    {
      label: 'Cosmetic Injectables',
      value: 'cosmetic_injectables',
    },
    // {
    //   label: 'Cryptocurrency Contracts & Hardware',
    //   value: 'cryptocurrency_contracts_and_hardware',
    // },
    {
      label: 'Electronics',
      value: 'electronics',
    },
    // {
    //   label: 'Home & Garden',
    //   value: 'home_and_garden',
    // },
    {
      label: 'Food',
      value: 'food',
    },
    {
      label: 'Fine Art',
      value: 'fine_art',
    },
    {
      label: 'Furniture',
      value: 'furniture',
    },
    {
      label: 'Heavy Equipment & Machinery',
      value: 'heavy_equipment_and_machinery',
    },
    {
      label: 'Intellectual Property',
      value: 'intellectual_property',
    },
    {
      label: 'IP Addresses',
      value: 'ip_addresses',
    },
    {
      label: 'Jewellery & Watches',
      value: 'jewellery_and_watches',
    },
    {
      label: 'Mobile Apps',
      value: 'mobile_apps',
    },
    {
      label: 'Movies & Music',
      value: 'movies_and_music',
    },
    {
      label: 'Pets & Livestock',
      value: 'pets_and_livestock',
    },
    {
      label: 'Services',
      value: 'services',
    },
    {
      label: 'Social Media Accounts',
      value: 'social_media_accounts',
    },
    {
      label: 'Sports & Recreation',
      value: 'sports_and_recreation',
    },
    {
      label: 'Tickets & Events',
      value: 'tickets_and_events',
    },
    {
      label: 'Tools & Hardware',
      value: 'tools_and_hardware',
    },
    {
      label: 'Toys & Hobbies',
      value: 'toys_and_hobbies',
    },
    {
      label: 'Video Games & Consoles',
      value: 'video_games_and_consoles',
    },
  ],
};
constants.TRANSACTION_TYPES_DISPLAY_NAME = {
  [constants.TRANSACTION_TYPES.DOMAIN_NAME]: gettext('Domain Name'),
  [constants.TRANSACTION_TYPES.GENERAL_MERCHANDISE]: gettext('General Merchandise'),
  [constants.TRANSACTION_TYPES.MILESTONE]: gettext('Milestone'),
  [constants.TRANSACTION_TYPES.MOTOR_VEHICLE]: gettext('Motor Vehicle'),
};
export default constants;
