import React, { useRef, useState } from 'react';
import Papa from 'papaparse';
import { withRouter } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import request from 'browser-request';
import window from 'window-shim';
import { CSVLink } from 'react-csv';

import TransactionConstants from 'spa/constants/TransactionConstants';
import {
  calculateFees,
  processTransactionJson,
} from 'spa/features/transaction/utils/transactionResponse';
import Currency from 'spa/components/Currency';
import { currencySymbols, InputField } from 'spa/components/StartTransaction/Fields';
import { Form } from 'react-final-form';
import { formatPrice } from 'spa/components/form/format';
import { Box, Dialog, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { composeValidators, email, strRequired } from 'spa/components/form/validate';
import { SubmitButton } from 'spa/components/StartTransaction';
import PaymentConstants from 'spa/constants/PaymentConstants';

import API from '../../../api';
import AuthenticationStore from '../../../stores/AuthenticationStore';

const { BULK_TRANSACTION_ROLES, TRANSACTION_TYPES } = TransactionConstants;

/**
 * Mapping from logical name to incoming CSV header value
 */
const HeaderFields = {
  TITLE: 'TRANSACTION_TITLE',
  ROLE: 'MY_ROLE',
  FEE_PAYER: 'ESCROW_FEE_PAID_BY',
  COUNTERPARTY: 'COUNTERPARTY_EMAIL',
  INSPECTION: 'INSPECTION_PERIOD_DAYS',
  CURRENCY: 'CURRENCY',
  PRICE: 'PRICE',
  ITEM: 'ITEM_NAME',
  DESCRIPTION: 'ITEM_DESCRIPTION',
};

/**
 * Maps CSV header fields to the InputField properties they should
 * use when presented for user editing.
 *
 * Two magic properties:
 *    'lc' will lower-case the field's value
 *    'options' is an array of options for a select field
 */
const HeaderFieldsToInputProps = {
  TITLE: {
    validate: composeValidators([strRequired, (value) => {
      if (value.length > 95) {
        return "Too long"
      }
      return undefined
    }]),
  },
  DESCRIPTION: {
    validate: composeValidators([strRequired, (value) => {
      if (value.length > 500) {
        return "Too long"
      }
      return undefined
    }]),
  },
  ITEM: {
    validate: composeValidators([strRequired, (value) => {
      if (value.length > 100) {
        return "Too long"
      }
      return undefined
    }]),
  },
  ROLE: {
    select: true,
    options: ['buyer', 'seller'],
    lc: true,
  },
  PRICE: {
    isNumeric: true,
    format: formatPrice,
    formatOnBlur: true,
    validate: (value) => {
      if (!value) {
        return "Required"
      }
      if (value < 100) {
        return "Price too low"
      }
      return undefined
    },
  },
  CURRENCY: {
    select: true,
    options: Object.keys(currencySymbols),
    lc: true,
  },
  INSPECTION: {
    isNumeric: true,
    validate: (value) => {
      if (!value) {
        return "Required"
      }
      if (value < 1) {
        return "Must be at least 1 day"
      }
      if (value > 30) {
        return "Must be at most 30 days"
      }
      return undefined
    },
    format: (value) => Math.floor(value),
    formatOnBlur: true,
  },
  FEE_PAYER: {
    select: true,
    options: ['buyer', 'seller', 'split'],
    lc: true,
  },
  COUNTERPARTY: {
    validate: composeValidators([strRequired, email]),
  },
};

/**
 * How long to intentionally sleep in between Transaction creations
 * @type {number}
 */
const DELAY_BETWEEN_CREATIONS_MS = 500;
/**
 * The type used for wire transfer / payment processing fees
 * @type {string}
 */
const PAYMENT_PROCESSING_FEE_TYPE = 'payment processing';

/**
 * Convenience function getting the customer's email address
 *
 * @returns {string}
 */
function getCustomerEmail() {
  return AuthenticationStore.getEmail();
}

/**
 * Renders an input field appropriate for editing/displaying the given CSV cell
 *
 * @param rowNumber Number of the row containing this cell
 * @param currency Currency used for the row containing this cell
 * @param fieldName Field name from HEADER_FIELDS
 * @param value Value the field currently has
 * @returns {JSX.Element}
 * @constructor
 */
const InputForField = ({ rowNumber, currency, fieldName, value }) => {
  const addlProps = fieldName === 'PRICE' ? { currency } : {};
  const baseProps = HeaderFieldsToInputProps.hasOwnProperty(fieldName)
    ? HeaderFieldsToInputProps[fieldName]
    : {};

  const onChange = () => {}

  let usedValue = baseProps.isNumeric ? parseFloat(value) : value;
  if (baseProps.lc) {
    usedValue = usedValue.toLowerCase();
  }

  if (!baseProps.select) {
    addlProps.highlightOnFocus = true;
  }

  if (baseProps.select) {
    addlProps.children = baseProps.options.map((child) => (
      <MenuItem key={`${rowNumber}-${fieldName}-${child}`} value={child}>
        {child}
      </MenuItem>
    ));

    const validator = baseProps.validate || (() => undefined)
    baseProps.validate = composeValidators([validator, strRequired, (givenValue) => {
      if (!baseProps.options.includes(givenValue.toLowerCase())) {
        return "Invalid value"
      }
      return undefined
    }])
  }

  return (
    <InputField
      name={`field-${rowNumber}-${fieldName}`}
      initialValue={usedValue}
      label={fieldName}
      onChange={onChange}
      shrinkLabel
      touchBeforeError={false}
      {...baseProps}
      {...addlProps}
    />
  );
};

/**
 * Component displaying a prospective Transaction's data for confirmation and editing
 *
 * @param rows Incoming data rows from a CSV file
 * @param onSubmit Callback invoked when submit button pressed
 * @returns {JSX.Element}
 * @constructor
 */
const TransactionConfirmationInput = ({ rows, onSubmit }) => {
  if (rows.length === 0) {
    return <div />;
  }

  const localOnSubmit = async (values) => {
    const reconstructedCsv = [...Array(rows.length).keys()].map((i) => {
      const reconstructedCsvData = {};
      Object.keys(HeaderFields).forEach((fieldName) => {
        reconstructedCsvData[HeaderFields[fieldName]] = values[`field-${i}-${fieldName}`];
      });
      return reconstructedCsvData;
    });

    onSubmit(reconstructedCsv);
  };

  return (
    <div>
      <Form
        onSubmit={localOnSubmit}
        subscription={{
          submitting: true,
          submitDisabled: true,
          hasValidationErrors: true,
          values: true,
        }}
      >
        {({ handleSubmit, submitting, submitDisabled, hasValidationErrors, values }) => (
          <div>
            <table className="transactionTable">
              <thead>
                <tr className="transactionTable-headerRow">
                  {Object.keys(HeaderFields).map((key) => (
                    <th key={`confirmHeader-${key}`}>{key.replace('_', ' ')}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, i) => {
                  const rowCurrency = values[`field-${i}-CURRENCY`];

                  return (
                    <tr key={`confirmRow-${row.title}`} className="transactionTable-row">
                      {Object.keys(HeaderFields).map((key) => (
                        <td
                          key={`confirmRow-${key}-${row.title}`}
                          className="transactionTable-cell"
                        >
                          <InputForField
                            fieldName={key}
                            value={row[HeaderFields[key]]}
                            rowNumber={i}
                            currency={rowCurrency}
                          />
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="createTransaction-submit">
              <SubmitButton
                fullWidth
                loading={submitting}
                disabled={submitDisabled || hasValidationErrors}
                type="submit"
                onClick={handleSubmit}
              >
                Bulk Create {rows.length} Transaction(s)
              </SubmitButton>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

/**
 * Calculate total amounts owing (by currency) and total fees across many transactions
 *
 * @param transactions Transaction objects
 * @returns {{grandTotal: {}, feeTypes: {}}}
 */
function calculateBulkTotals(transactions) {
  const customerEmail = getCustomerEmail();

  const feeTypes = {};
  const grandTotal = {};
  const grandTotalFees = {};

  transactions.forEach((txn) => {
    const currency = txn.currency;
    const processed = processTransactionJson(txn, {});
    const calculatedFees = calculateFees(processed, customerEmail);

    let total = 0;
    const totalFees = {};
    txn.items.forEach((item) => {
      item.schedule.forEach((schedItem) => {
        if (schedItem.payer_customer === customerEmail) {
          total += parseFloat(schedItem.amount);
        }
      });
    });

    Object.keys(calculatedFees).forEach((feeFullName) => {
      const feeType = feeFullName.replace('Fee', '');
      const feeAmount = calculatedFees[feeFullName];

      if (!feeAmount) {
        return;
      }

      if (!totalFees.hasOwnProperty(feeType)) {
        totalFees[feeType] = 0;
      }
      if (!feeTypes.hasOwnProperty(feeType)) {
        feeTypes[feeType] = true;
      }

      totalFees[feeType] += feeAmount;
    });

    const processingFeeAmount = txn.processingFee;
    if (processingFeeAmount > 0) {
      totalFees[PAYMENT_PROCESSING_FEE_TYPE] = processingFeeAmount;
      feeTypes[PAYMENT_PROCESSING_FEE_TYPE] = true;
    }

    txn.total = total;
    txn.totalFees = totalFees;

    if (!grandTotal.hasOwnProperty(currency)) {
      grandTotal[currency] = 0;
      grandTotalFees[currency] = {};
    }

    grandTotal[currency] += txn.totalIncludingProcessingFee;

    Object.keys(totalFees).forEach((feeType) => {
      if (!grandTotalFees[currency].hasOwnProperty(feeType)) {
        grandTotalFees[currency][feeType] = 0;
      }
      grandTotalFees[currency][feeType] += totalFees[feeType];
    });
  });

  return { feeTypes, grandTotal };
}

/**
 * Creates a link to download a CSV summary of created transactions
 *
 * @param transactions
 * @param feeTypes
 * @param endDateTime
 * @param grandTotal
 * @param availableValues
 * @returns {JSX.Element}
 * @constructor
 */
function CSVDownloadSummary({ transactions, feeTypes, endDateTime, grandTotal, availableValues }) {
  const header = ['TRANSACTION_ID', 'DESCRIPTION', 'CURRENCY', 'AMOUNT'];
  const currencies = {};

  Object.keys(feeTypes).forEach((feeType) => {
    header.push(`${feeType.toUpperCase()}_FEE`);
  });

  header.push('TOTAL_AMOUNT_PAYABLE');

  const download = [header];
  transactions.forEach((txn) => {
    const data = [txn.id, txn.description, txn.currency.toUpperCase(), txn.total];
    Object.keys(feeTypes).forEach((feeType) => {
      data.push(txn.totalFees[feeType] || 0);
    });
    data.push(txn.totalIncludingProcessingFee);
    download.push(data);

    currencies[txn.currency] = true;
  });

  download.push([])
  if (endDateTime) {
    download.push([`Bulk Transactions created at ${endDateTime.toUTCString()}`])
  }

  Object.keys(currencies).forEach((currency) => {
    const availableForThisCurrency = availableValues[`available-${currency}`];
    const grandTotalForThisCurrency = grandTotal[currency];
    const payable = grandTotalForThisCurrency - availableForThisCurrency;

    download.push([])
    download.push([`<<<${currency.toUpperCase()} SUMMARY>>>`]);
    download.push([`Grand Total: ${currencySymbols[currency]}${grandTotalForThisCurrency}`]);
    download.push([
      `Available Funds (User Entered): ${currencySymbols[currency]}${availableForThisCurrency}`,
    ]);
    download.push([`Total Amount Payable: ${currencySymbols[currency]}${payable}`]);
  });

  download.push([]);
  download.push(['Next Steps:']);
  download.push(['Pay the Net Total Payable to Escrow.com']);
  download.push([
    'Email payments@escrow.com and your Escrow.com account manager this file, and a wire receipt',
  ]);

  const fileName = endDateTime ? `escrow-bulk-transactions-${endDateTime.toUTCString()}.csv` :
    "escrow-bulk-transactions-incomplete.csv"

  return (<CSVLink data={download} filename={fileName}>
    Download Summary
  </CSVLink>);
}

/**
 * Component displaying a list of created Transactions. Also shows a summary.
 *
 * @param transactions List of data representing Transaction objects
 * @returns {JSX.Element}
 * @constructor
 */
const CreatedTransactionList = ({ transactions, endDateTime }) => {
  if (transactions.length === 0) {
    return <div />;
  }

  const { feeTypes, grandTotal } = calculateBulkTotals(transactions);

  const thCellStyle = { 'padding-left': '10px' };

  return (
    <div>
      <h1>Created Transactions</h1>
      <table className="transactionTable">
        <thead style={{ 'text-align': 'left' }}>
          <tr className="transactionTable-headerRow">
            <th style={thCellStyle}>Transaction ID</th>
            <th style={thCellStyle}>Description</th>
            <th style={thCellStyle}>Amount</th>
            {Object.keys(feeTypes).map((feeType) => (
              <th style={thCellStyle} key={`fee-${feeType}`}>
                Fee Payable ({feeType})
              </th>
            ))}
            <th>Total Amount Payable</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((row) => (
            <tr className="transactionTable-row" key={`createdTransaction-${row.id}`}>
              <td className="transactionTable-cell">
                <strong>{row.id}</strong>
              </td>
              <td className="transactionTable-cell">{row.description}</td>
              <td className="transactionTable-cell">
                <Currency code={row.currency} amount={row.total} />
              </td>
              {Object.keys(feeTypes).map((feeType) => (
                <td className="transactionTable-cell" key={`fee-${feeType}-${row.id}`}>
                  <Currency code={row.currency} amount={row.totalFees[feeType] || 0} />
                </td>
              ))}
              <td className="transactionTable-cell">
                <Currency code={row.currency} amount={row.totalIncludingProcessingFee} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <h4 style={{ color: 'green' }}>Successfully created {transactions.length} transactions</h4>
      {endDateTime && (
        <div style={{ color: 'green', fontSize: '8pt' }}>on {endDateTime.toUTCString()}</div>
      )}
      <Form
        onSubmit={() => {}}
        subscription={{
          values: true,
          hasValidationErrors: true,
        }}
      >
        {({ values, hasValidationErrors }) => (
          <div>
            {Object.keys(grandTotal).map((currency) => (
              <Box
                key={`summary-${currency}`}
                sx={{
                  borderColor: 'primary.main',
                  border: 1,
                  width: '50%',
                  padding: '10px',
                  borderRadius: '2px',
                  marginLeft: '10px',
                  marginTop: '2em',
                }}
              >
                <h2>{currency.toUpperCase()} Summary</h2>
                <h4>
                  Total Amount Payable: <Currency code={currency} amount={grandTotal[currency]} />
                </h4>
                <div>
                  <InputField
                    name={`available-${currency}`}
                    isNumeric
                    label="Available Funds"
                    formatOnBlur
                    format={formatPrice}
                    currency={currency}
                    initialValue={0}
                    highlightOnFocus
                    validate={(value) => {
                      if (value < 0) {
                        return "Must be positive"
                      }
                      if (value > grandTotal[currency]) {
                        return "Must be at most the Amount Payable"
                      }
                      return undefined
                    }}
                  />
                  <h4>
                    Net Total Payable:{' '}
                    <Currency
                      code={currency}
                      amount={grandTotal[currency] - values[`available-${currency}`]}
                    />
                  </h4>
                </div>
              </Box>
            ))}
            <br />
            <br />
            {!hasValidationErrors && (
              <div>
                <Box
                  sx={{
                    justifyContent: 'center',
                    borderColor: 'primary.main',
                    border: 1,
                    width: '50%',
                    padding: '10px',
                    borderRadius: '2px',
                    marginLeft: '10px',
                  }}
                >
                  <h3>Next Steps</h3>
                  <Box
                    sx={{
                      marginLeft: '3em',
                      marginBottom: '1em',
                    }}
                  >
                    <ol>
                      <li>Pay the Net Total Payable to Escrow.com.</li>
                      <br />
                      <li>
                        Email to payments@escrow.com and your Escrow.com account manager the following:
                        <br />
                        <Box
                          sx={{
                            marginLeft: '1em',
                          }}
                        >
                          <ul>
                            <li>(a) Wire receipt of the payment from your bank</li>
                            <li>(b) List of Transaction IDs being funded by this payment</li>
                            <li>(c) Amount of available funds you wish to utilise</li>
                          </ul>
                        </Box>
                      </li>
                    </ol>
                  </Box>

                  <Box
                    sx={{
                      paddingTop: '2em',
                    }}
                  >
                    Sending a download of the above summary will satisfy (b) and (c).
                  </Box>
                </Box>
                <CSVDownloadSummary
                  transactions={transactions}
                  feeTypes={feeTypes}
                  endDateTime={endDateTime}
                  grandTotal={grandTotal}
                  availableValues={values}
                />
              </div>
            )}
          </div>
        )}
      </Form>
    </div>
  );
};

/**
 * Converts incoming CSV data into a payload suitable for the create-transaction API
 *
 * @param me Email address of the customer
 * @param csvItem An individual CSV-data row
 */
function convertCSVInput(me, csvItem) {
  const role = csvItem[HeaderFields.ROLE].toLowerCase();
  const feePayerRole = csvItem[HeaderFields.FEE_PAYER].toLowerCase();

  const buyer = role === BULK_TRANSACTION_ROLES.BUYER ? me : csvItem[HeaderFields.COUNTERPARTY];
  const seller = role === BULK_TRANSACTION_ROLES.SELLER ? me : csvItem[HeaderFields.COUNTERPARTY];
  const inspectionPeriodSeconds = csvItem[HeaderFields.INSPECTION] * 86400;

  let fees;

  if (feePayerRole === 'split') {
    fees = [
      {
        payer_customer: buyer,
        type: 'escrow',
        beneficiary_customer: 'escrow',
        split: 0.5,
      },
      {
        payer_customer: seller,
        type: 'escrow',
        beneficiary_customer: 'escrow',
        split: 0.5,
      },
    ];
  } else {
    const feePayer = role === feePayerRole ? me : csvItem[HeaderFields.COUNTERPARTY];
    fees = [
      {
        payer_customer: feePayer,
        type: 'escrow',
        beneficiary_customer: 'escrow',
        split: 1.0,
      },
    ];
  }

  return {
    parties: [
      {
        role: BULK_TRANSACTION_ROLES.BUYER,
        customer: buyer,
      },
      {
        role: BULK_TRANSACTION_ROLES.SELLER,
        customer: seller,
      },
      {
        role: BULK_TRANSACTION_ROLES.PARTNER,
        customer: me,
      },
    ],
    currency: csvItem[HeaderFields.CURRENCY].toLowerCase(),
    description: `[BC] ${csvItem[HeaderFields.TITLE]}`,
    items: [
      {
        title: csvItem[HeaderFields.ITEM],
        description: csvItem[HeaderFields.DESCRIPTION],
        type: TRANSACTION_TYPES.DOMAIN_NAME,
        category: 'domain_name',
        inspection_period: inspectionPeriodSeconds,
        quantity: 1,
        schedule: [
          {
            amount: csvItem[HeaderFields.PRICE],
            payer_customer: buyer,
            beneficiary_customer: seller,
          },
        ],
        fees,
      },
    ],
  };
}

/**
 * Invokes the get-payment-methods API to calculate processing fees for a transaction
 *
 * @param transactionData Data representing a transaction
 * @param setTransactions React state callback to which the resulting object should be appended
 * @returns {
 Promise < void >
 }
 */
async function augmentWithProcessingFeeInformation(transactionData, setTransactions) {
  const getPaymentMethodsResult = await API.getPaymentMethods(transactionData.id);

  const wireTransferPayment = getPaymentMethodsResult.available_payment_methods.filter(
    (paymentObj) => paymentObj.type === PaymentConstants.PAYMENT_METHODS.WIRE_TRANSFER
  );

  const wireTransferFee = wireTransferPayment[0].fees.filter(
    (feeObj) => feeObj.type === 'intermediary'
  );

  const processingFee = wireTransferFee.length > 0 ? parseFloat(wireTransferFee[0].amount) : 0;

  transactionData.totalIncludingProcessingFee = parseFloat(wireTransferPayment[0].total);
  transactionData.processingFee = processingFee;

  /**
   * Escrow's APIs return "euro" instead of "eur" :(
   */
  if (transactionData.currency.toLowerCase() === 'euro') {
    transactionData.currency = 'eur';
  }

  setTransactions((txns) => txns.concat([transactionData]));
}

async function handleCreationComplete(setTransactions, errorCallback, error, response, body) {
  if (error !== null) {
    errorCallback(error);
    return;
  }
  if (response.status !== 201) {
    if (body !== null) {
      errorCallback(body.errors);
      return;
    }

    return {
      description: `Got response status ${response.status}`,
    };
  }

  await augmentWithProcessingFeeInformation(body, setTransactions);
  errorCallback(null);
}

/**
 * Creates one transaction per given data row
 *
 * @param data An array, one entry per transaction
 * @param setTransactions Callback to which the created transaction should be appended
 * @param onSuccess Callback when all transactions are created
 * @returns {
 Promise < void >
 }
 */
async function doCreateTransactions(data, setTransactions, onSuccess) {
  const customerEmail = getCustomerEmail();

  const numTransactionsExpected = data.length;

  for (let i = 0; i < numTransactionsExpected; i++) {
    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    const row = data[i];
    const apiInput = convertCSVInput(customerEmail, row);
    request.post(
      {
        url: `${window.config.v4_api_endpoint}/transaction`,
        json: apiInput,
        contentType: 'application/json',
        withCredentials: true,
      },
      handleCreationComplete.bind(this, setTransactions, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      })
    );

    // We're INTENTIONALLY doing one request at a time, in serial sequence
    // eslint-disable-next-line no-await-in-loop
    await promise;
    // We're also waiting to try and avoid rate limits
    if (i < numTransactionsExpected - 1) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((r) => setTimeout(r, DELAY_BETWEEN_CREATIONS_MS));
    }
  }

  onSuccess();
}

async function handleParseComplete(onFailure, onSuccess, setCsvRows, results, files) {
  if (results.errors.length > 0) {
    alert(`Failed to parse CSV: ${JSON.stringify(results.errors)}`);
    onFailure();
    return;
  }

  setCsvRows(results.data);

  onSuccess();
}

/**
 * Component allowing the user to upload a CSV file
 *
 * @param csvRows
 * @param setCsvRows
 * @param createdTransactions
 * @param transactionsToCreate
 * @param onSubmit
 * @param onFailure
 * @returns {
 JSX.Element;
 }
 * @constructor
 */
const FileUploadTransactionCreationComponent = ({
  csvRows,
  setCsvRows,
  createdTransactions,
  transactionsToCreate,
  onSubmit,
  onFailure,
  endDateTime,
}) => {
  const csvInput = useRef(null);
  const [processing, setProcessing] = useState(false);

  const localOnFailure = () => {
    setProcessing(false)
    onFailure()
  }

  const onBulkUpload = (files) => {
    setProcessing(true);

    Papa.parse(files[0], {
      header: true,
      skipEmptyLines: 'greedy',
      complete: handleParseComplete.bind(
        this,
        localOnFailure,
        () => {
          setProcessing(false);
        },
        setCsvRows
      ),
      error: (err, file, inputElem, reason) => {
        setProcessing(false);
        alert(err);
        onFailure();
      },
    });
  };

  return (
    <div>
      <CreatedTransactionList transactions={createdTransactions} endDateTime={endDateTime} />
      <TransactionConfirmationInput rows={csvRows} onSubmit={onSubmit} />
      <Dialog
        open={transactionsToCreate > createdTransactions.length}
        onClose={() => {}}
        maxWidth={'sm'}
      >
        <DialogTitle>
          <h2>Creating Transactions</h2>
        </DialogTitle>
        <DialogContent>
          <LoadingButton
            loading
            endIcon={<UploadFileIcon />}
            variant="outlined"
            color="primary"
            loadingPosition="end"
            size="large"
          >
            Creating {transactionsToCreate - createdTransactions.length} More Transactions...
          </LoadingButton>
        </DialogContent>
      </Dialog>
      {csvRows.length === 0 && createdTransactions.length === 0 && transactionsToCreate === 0 && (
        <div>
          <input
            type="file"
            accept=".csv"
            ref={csvInput}
            hidden
            onChange={(event) => onBulkUpload(event.target.files)}
          />
          <LoadingButton
            onClick={() => {
              csvInput.current.click();
            }}
            loading={processing}
            endIcon={<UploadFileIcon />}
            variant="outlined"
            color="primary"
            loadingPosition="end"
            size="large"
          >
            Upload CSV for Bulk Transactions
          </LoadingButton>
        </div>
      )}
    </div>
  );
};
const BulkTransactionCreationPage = ({}) => {
  const [csvRows, setCsvRows] = useState([]);
  const [createdTransactions, setCreatedTransactions] = useState([]);
  const [readyTransactionData, setReadyTransactionData] = useState([]);
  const [inProgressTransactions, setInProgressTransactions] = useState([]);
  const [transactionCreationEndTime, setTransactionCreationEndTime] = useState(null);

  const onFailure = () => {
    setCsvRows([])
  };

  const onSuccess = () => {
    setReadyTransactionData([]);
    setInProgressTransactions([]);
    setTransactionCreationEndTime(new Date());
  };

  const onUnconfirmedSubmit = (csv) => {
    setReadyTransactionData(csv);
  };

  const onConfirmedSubmit = async () => {
    const transactionsToCreate = readyTransactionData;
    setInProgressTransactions(transactionsToCreate);
    setCsvRows([]);
    setReadyTransactionData([]);
    try {
      await doCreateTransactions(transactionsToCreate, setCreatedTransactions, onSuccess);
    } catch (e) {
      alert(JSON.stringify(e))
      setInProgressTransactions([]);
      setTransactionCreationEndTime(new Date());
    }
  };

  return (
    <div>
      <Dialog
        open={inProgressTransactions.length === 0 && readyTransactionData.length > 0}
        onClose={() => {
          setCsvRows(readyTransactionData);
          setReadyTransactionData([]);
        }}
        maxWidth={'sm'}
      >
        <DialogTitle>
          <h2>Confirm Creating Transactions</h2>
        </DialogTitle>
        <DialogContent>
          Are you sure you want to create {readyTransactionData.length} Escrow transactions?
        </DialogContent>
        <SubmitButton fullWidth type="submit" onClick={onConfirmedSubmit}>
          Bulk Create {readyTransactionData.length} Transaction(s)
        </SubmitButton>
      </Dialog>
      <FileUploadTransactionCreationComponent
        csvRows={csvRows}
        setCsvRows={setCsvRows}
        createdTransactions={createdTransactions}
        transactionsToCreate={inProgressTransactions.length}
        onFailure={onFailure}
        onSubmit={onUnconfirmedSubmit}
        endDateTime={transactionCreationEndTime}
      />
    </div>
  );
};

export default withRouter(BulkTransactionCreationPage);
